// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Preview_preview__0xytr {\r\n  flex: 1 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow: auto;\r\n  word-wrap: break-all;\r\n}\r\n\r\n.Preview_preview__0xytr h5,\r\n.Preview_preview__0xytr p {\r\n  width: 100%;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n  word-break: break-word;\r\n}\r\n\r\n.Preview_preview__0xytr h5 {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n  color: var(--text-color-chat);\r\n}\r\n\r\n.Preview_preview__0xytr p {\r\n  margin: 5px 0 15px;\r\n  white-space: break-spaces;\r\n}", "",{"version":3,"sources":["webpack://./src/domain/UserModal/pages/Preview/index.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,oBAAoB;AACtB;;AAEA;;EAEE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".preview {\r\n  flex: 1 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow: auto;\r\n  word-wrap: break-all;\r\n}\r\n\r\n.preview h5,\r\n.preview p {\r\n  width: 100%;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n  word-break: break-word;\r\n}\r\n\r\n.preview h5 {\r\n  font-size: 14px;\r\n  font-weight: 600;\r\n  color: var(--text-color-chat);\r\n}\r\n\r\n.preview p {\r\n  margin: 5px 0 15px;\r\n  white-space: break-spaces;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": "Preview_preview__0xytr"
};
export default ___CSS_LOADER_EXPORT___;
