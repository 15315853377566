// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditRank_select__gCKOX {\r\n  margin: 10px 0;\r\n}\r\n\r\nbutton.EditRank_bottom__Tit6A {\r\n  margin-top: auto;\r\n}\r\n\r\n.EditRank_badge__7drx2 {\r\n  background-color: #0894E2;\r\n  color: #001b2a;\r\n  font-weight: 600;\r\n  padding: 0 5px;\r\n  border-radius: 5px;\r\n  line-height: 21px;\r\n  font-size: 12px;\r\n  text-transform: uppercase;\r\n  display: inline-block;\r\n  margin: 8px 0;\r\n}", "",{"version":3,"sources":["webpack://./src/domain/UserModal/pages/EditRank/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,qBAAqB;EACrB,aAAa;AACf","sourcesContent":[".select {\r\n  margin: 10px 0;\r\n}\r\n\r\nbutton.bottom {\r\n  margin-top: auto;\r\n}\r\n\r\n.badge {\r\n  background-color: #0894E2;\r\n  color: #001b2a;\r\n  font-weight: 600;\r\n  padding: 0 5px;\r\n  border-radius: 5px;\r\n  line-height: 21px;\r\n  font-size: 12px;\r\n  text-transform: uppercase;\r\n  display: inline-block;\r\n  margin: 8px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "EditRank_select__gCKOX",
	"bottom": "EditRank_bottom__Tit6A",
	"badge": "EditRank_badge__7drx2"
};
export default ___CSS_LOADER_EXPORT___;
