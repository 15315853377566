import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { DiscordAlt as Discord } from '@styled-icons/boxicons-logos/DiscordAlt';
// import { Vk } from '@styled-icons/entypo-social/Vk';


import ChatBtn from '../../components/ChatBtn';
import Button from '../../components/Button';
import Chat from '../../domain/Chat';
import { ReactComponent as JackpotIcon } from './resources/jackpot.svg';
import { ReactComponent as CoinflipIcon } from './resources/coinflip.svg';
import { ReactComponent as UpgraderIcon } from './resources/upgrader.svg';
import logo from '../../resources/images/logo-spin.gif';
import coin from '../../resources/images/coin.png';
import blueLogo from '../../resources/images/blueLogo.png';
import allLogo from '../../resources/images/logowname.png';
import chaticon from './resources/log-in-01.svg';
import settings from './resources/settings.svg';
import emoji from './resources/emoji.png';
import styles from './index.module.css';

const { helpers, events, tf2_jackpot, tf2_coinflip, tf2_mines, chat, store } = window.insolve;

const GameLink = ({ link, title, subtext, icon }) => (
  <NavLink to={link} className={({ isActive }) => `${styles.link} ${isActive ? styles.active : ''}`}>
    {icon}

    <p>{title}</p>
    {subtext && (
      <p className={styles.desc}>
        <img src={coin} alt="" />
        <span>{subtext}</span>
      </p>
    )}
  </NavLink>
)

const Navbar = () => {
  const [jackpotValue, setJackpotValue] = useState(0);
  const [coinflipValue, setCoinflipValue] = useState(0);
  const [minesValue, setMinesValue] = useState(0);
  const [self, setSelf] = useState(store.get('user') || undefined); // todo: this could be a useSelf hook
  const [showChat,setShowChat]= useState(false)
  // const onJackpotInit = data => {
  //   setJackpotValue(data.items.reduce((a, b) => +a + +b.price, 0));
  // }

  const onJackpotValue = data => {
    setJackpotValue(data);
  }

  const onCoinflipValue = data => {
    setCoinflipValue(data);
  }

  const onMinesValue = data => {
    setMinesValue(data);
  }

  useEffect(() => {
    // events.on('tf2_jackpot:init', onJackpotInit);
    events.on('tf2_jackpot:value', onJackpotValue);
    events.on('tf2_coinflip:value', onCoinflipValue);
    events.on('tf2_mines:value', onMinesValue);

    tf2_jackpot.requestValue();
    tf2_coinflip.requestValue();
    tf2_mines.requestValue();

    return () => {
      // events.off('tf2_jackpot:init', onJackpotInit);
      events.off('tf2_jackpot:value', onJackpotValue);
      events.off('tf2_coinflip:value', onCoinflipValue);
      events.off('tf2_mines:value', onMinesValue);
    }
  }, []);

  useEffect(() => {
    events.on('login', setSelf);

    return () => {
      events.off('login', setSelf);

    }
  }, []);

  return (
    <>
      <nav className={styles.sidebar}>
        <Link to="/" className={styles.logo}>
          <img src={allLogo} alt="" />
        </Link>

        {!!self ?
          <div className={styles.games}>
            <div style={{ display: 'flex', alignItems: 'center', color: 'white', justifyContent: 'space-around', marginBottom: '20px' }}>
              <h1>Chat</h1>
              <img src={chaticon} alt="Chat Icon" className={styles.leaveChat} onClick={() => setShowChat(false)} />
            </div>
            <div className={styles.chat_users_cont}>
            </div>
            <Chat />
            {/* <GameLink link="/coinflip" title="Coinflip" subtext={helpers.formatBalance(coinflipValue)} icon={<CoinflipIcon className={styles.strokeTheme} />} /> */}
            {/* <GameLink link="/mines" title="Mines" subtext={helpers.formatBalance(minesValue)} icon={<UpgraderIcon className={styles.fillTheme} />} /> */}
          </div>
          :
          <div className={styles.containerEndSidebar}>
            <ChatBtn
              width="252px"
              height="45px"
              textColor="#676876"
              text="Log in to chat"
              color="#1D1E22"
              onClick={window.insolve.user.signIn}
            />
            <div className={styles.endSideBar}>
              <img src={emoji} alt="emoji" style={{ width: '25px', height: '25px' }} />
              <img src={settings} alt="setting" style={{ width: '25px', height: '25px' }} />
              <p>Rules</p>
              <Button type="button" onClick='' className={styles.steam} width="150" color="#fff">
                <span>Send</span>
              </Button>
            </div>
          </div>
        }
        {/* <div className={styles.social}>
          <a href="https://twitter.com/tf2_double" target="_blank" rel="noopener noreferrer">
            <Twitter />
          </a>

          <a href="https://discord.gg/kjH4nPcBpD" target="_blank" rel="noopener noreferrer">
            <Discord />
          </a>

          <a href="https://vk.com/tf2double" target="_blank" rel="noopener noreferrer">
            <Vk />
          </a>
        </div> */}
      </nav>
    </>
  );
}

export default Navbar;