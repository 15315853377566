// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChatBtn_button__S\\+4oi {\r\n    background-color: #4CAF50; /* Green background */\r\n    border: none;\r\n    color: white; /* White text */\r\n    text-align: center;\r\n    text-decoration: none;\r\n    display: inline-block;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    border-radius: 8px; /* Rounded corners */\r\n    transition: background-color 0.3s; /* Smooth transition on hover */\r\n  }\r\n  \r\n  .ChatBtn_button__S\\+4oi:hover {\r\n    background-color: #45a049; /* Darker green on hover */\r\n  }\r\n  ", "",{"version":3,"sources":["webpack://./src/components/ChatBtn/index.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,qBAAqB;IAChD,YAAY;IACZ,YAAY,EAAE,eAAe;IAC7B,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,kBAAkB,EAAE,oBAAoB;IACxC,iCAAiC,EAAE,+BAA+B;EACpE;;EAEA;IACE,yBAAyB,EAAE,0BAA0B;EACvD","sourcesContent":[".button {\r\n    background-color: #4CAF50; /* Green background */\r\n    border: none;\r\n    color: white; /* White text */\r\n    text-align: center;\r\n    text-decoration: none;\r\n    display: inline-block;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    border-radius: 8px; /* Rounded corners */\r\n    transition: background-color 0.3s; /* Smooth transition on hover */\r\n  }\r\n  \r\n  .button:hover {\r\n    background-color: #45a049; /* Darker green on hover */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "ChatBtn_button__S+4oi"
};
export default ___CSS_LOADER_EXPORT___;
