// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Banned_notFound__HQMpM {\r\n  display: flex;\r\n  text-align: center;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  height: 100%;\r\n  /* height: calc(100vh - 82px - 44px); */\r\n  transform: translateY(-40px);\r\n}\r\n\r\n.Banned_notFound__HQMpM h3 {\r\n  font-size: 1.8em;\r\n  font-weight: 600;\r\n}\r\n\r\n.Banned_notFound__HQMpM p {\r\n  margin: 25px 0;\r\n  color: var(--text-color-tertiary);\r\n}\r\n\r\n.Banned_notFound__HQMpM a {\r\n  font-weight: 600;\r\n  transition: opacity 300ms;\r\n}\r\n\r\n.Banned_notFound__HQMpM a svg {\r\n  height: 16px;\r\n  margin-left: 5px;\r\n}\r\n\r\n.Banned_notFound__HQMpM a:hover {\r\n  opacity: .5;\r\n}\r\n\r\n.Banned_notFound__HQMpM p span {\r\n  color: var(--text-color-primary);\r\n}", "",{"version":3,"sources":["webpack://./src/scenes/Banned/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,uCAAuC;EACvC,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".notFound {\r\n  display: flex;\r\n  text-align: center;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  height: 100%;\r\n  /* height: calc(100vh - 82px - 44px); */\r\n  transform: translateY(-40px);\r\n}\r\n\r\n.notFound h3 {\r\n  font-size: 1.8em;\r\n  font-weight: 600;\r\n}\r\n\r\n.notFound p {\r\n  margin: 25px 0;\r\n  color: var(--text-color-tertiary);\r\n}\r\n\r\n.notFound a {\r\n  font-weight: 600;\r\n  transition: opacity 300ms;\r\n}\r\n\r\n.notFound a svg {\r\n  height: 16px;\r\n  margin-left: 5px;\r\n}\r\n\r\n.notFound a:hover {\r\n  opacity: .5;\r\n}\r\n\r\n.notFound p span {\r\n  color: var(--text-color-primary);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": "Banned_notFound__HQMpM"
};
export default ___CSS_LOADER_EXPORT___;
