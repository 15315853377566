// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditBadge_input__iAdZY {\r\n  margin: 10px 0;\r\n  background-color: var(--bg-color-2);\r\n  outline: 0;\r\n  border: 0;\r\n  border-radius: 5px;\r\n  height: 40px;\r\n  line-height: 40px;\r\n  padding: 0 15px;\r\n  color: var(--text-color-primary);\r\n}\r\n\r\nbutton.EditBadge_bottom__PgSLc {\r\n  margin-top: auto;\r\n}\r\n\r\n.EditBadge_badge__OzP1a {\r\n  background: #0894E2;\r\n  color: #001b2a;\r\n  font-weight: 600;\r\n  padding: 0 5px;\r\n  border-radius: 5px;\r\n  line-height: 21px;\r\n  font-size: 12px;\r\n  display: inline-block;\r\n  margin: 8px 0;\r\n}", "",{"version":3,"sources":["webpack://./src/domain/UserModal/pages/EditBadge/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mCAAmC;EACnC,UAAU;EACV,SAAS;EACT,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,qBAAqB;EACrB,aAAa;AACf","sourcesContent":[".input {\r\n  margin: 10px 0;\r\n  background-color: var(--bg-color-2);\r\n  outline: 0;\r\n  border: 0;\r\n  border-radius: 5px;\r\n  height: 40px;\r\n  line-height: 40px;\r\n  padding: 0 15px;\r\n  color: var(--text-color-primary);\r\n}\r\n\r\nbutton.bottom {\r\n  margin-top: auto;\r\n}\r\n\r\n.badge {\r\n  background: #0894E2;\r\n  color: #001b2a;\r\n  font-weight: 600;\r\n  padding: 0 5px;\r\n  border-radius: 5px;\r\n  line-height: 21px;\r\n  font-size: 12px;\r\n  display: inline-block;\r\n  margin: 8px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "EditBadge_input__iAdZY",
	"bottom": "EditBadge_bottom__PgSLc",
	"badge": "EditBadge_badge__OzP1a"
};
export default ___CSS_LOADER_EXPORT___;
