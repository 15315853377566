import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Button from '../../components/Button';
import UserModal from '../UserModal';

import { Menu } from '@styled-icons/heroicons-outline/Menu';
import { SettingsOutline } from '@styled-icons/evaicons-outline/SettingsOutline';
import { SignOut } from '@styled-icons/octicons/SignOut';
import { QuestionMarkCircle } from '@styled-icons/evaicons-solid/QuestionMarkCircle';
import { Trophy } from '@styled-icons/fa-solid/Trophy';
import { ShieldFillCheck } from '@styled-icons/bootstrap/ShieldFillCheck';
import { DocumentQuestionMark } from '@styled-icons/fluentui-system-filled/DocumentQuestionMark';
import { Envelope } from '@styled-icons/zondicons/Envelope';
import { Lock } from '@styled-icons/boxicons-solid/Lock';
import { SteamSymbol } from '@styled-icons/fa-brands/SteamSymbol';
import { ReactComponent as JackpotIcon } from '../../domain/Sidebar/resources/jackpot.svg';
import { ReactComponent as CoinflipIcon } from '../../domain/Sidebar/resources/coinflip.svg';

import logo from '../../resources/images/logo.png';
import homeLine from '../../resources/images/homeLine.svg';
import coinHand from '../../resources/images/coinHand.svg';
import coinsStacked from '../../resources/images/coinsStacked.svg';
import styles from './index.module.css';
import discord from '../../resources/images/discord.png';
import twitter from '../../resources/images/twitter.png';

const { store, events, user } = window.insolve;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [self, setSelf] = useState(store.get('user') || undefined); 

  const updateXp = xp => {
    setSelf(prev => ({ ...prev, xp }));
  }

  const updateRank = rank => {
    setSelf(prev => ({ ...prev, rank }));
  }

  const updateLevelProgress = data => {
    setSelf(prev => ({ ...prev, ...data }));
  }

  const openSettings = () => events.emit('internal:toggleUserModal:settings', self);
  const openProfile = () => events.emit('internal:toggleUserModal', self);

  useEffect(() => {
    events.on('login', setSelf);
    events.on('user:updateValue-rank', updateRank);
    events.on('user:updateValue-xp', updateXp);
    events.on('user:levelProgress', updateLevelProgress);
    return () => {
      events.off('login', setSelf);
      events.off('user:updateValue-rank', updateRank);
      events.off('user:updateValue-xp', updateXp);
      events.off('user:levelProgress', updateLevelProgress);
    }

  }, []);

  const isAdmin = self?.rank >= 4;

  console.log('window: ', window.insolve)

  return (
    <>
      <nav data-open={isOpen ? true : null}>
        <div className={styles.upernave}>
          <a href="https://discord.gg/kjH4nPcBpD" target="_blank" rel="noopener noreferrer">
            <img src={discord} alt="Discord" className={styles.icon} style={{ marginRight: 25 }} />
          </a>
          <a href="https://twitter.com/tf2_double" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Twitter" className={styles.icon} style={{ marginRight: 25 }} />
          </a>

          <NavLink onClick={() => setIsOpen(false)} to="/provably-fair" className={({ isActive }) => `${styles.link} ${isActive ? styles.active : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
            <span>Provably Fair</span>
          </NavLink>
          <NavLink onClick={() => setIsOpen(false)} to="/faq" className={({ isActive }) => `${styles.link} ${isActive ? styles.active : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
            <span>Frequently Asked</span>
          </NavLink>
          <NavLink onClick={() => setIsOpen(false)} to="/privacy-policy" className={({ isActive }) => `${styles.link} ${isActive ? styles.active : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
            <span>Privacy Policy</span>
          </NavLink>
          <NavLink onClick={() => setIsOpen(false)} to="/support" className={({ isActive }) => `${styles.link} ${isActive ? styles.active : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
            <span>Support</span>
          </NavLink>
        </div>
        <div className={styles.navbar}>
          <div className={styles.overlay} onClick={() => setIsOpen(false)} />

          <a href="/" className={styles.logoMobileBig}><img src={logo} alt="" /></a>
          <Menu className={styles.menu} onClick={() => setIsOpen(prev => !prev)} />

          <div className={styles.links}>
            <a href="/" className={styles.logoMobile}><img src={logo} alt="" /></a>

            <div className={styles.linkContainer}>
              <div className={styles.left}>


                <NavLink onClick={() => setIsOpen(false)} to="/home" className={({ isActive }) => `${styles.link} ${isActive ? styles.active : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={homeLine} alt="Home" style={{ width: 20, height: 20, marginRight: 5 }} />
                  <span>Home</span>
                </NavLink>

                <div style={{ display: 'flex', alignItems: 'center', minWidth: 'max-content', marginRight: "36px" }}>
  <a onClick={() => setIsOpen(false)} href="/coinflip" className={`${styles.link}`} style={{ display: 'flex', alignItems: 'center' }}>
    <img src={coinHand} alt="coinflip" style={{ width: 20, height: 20, marginRight: 5 }} />
    <span style={{ marginRight: -25 }}>coinflip</span>
  </a>
  <button className={styles.btnLink} style={{ fontSize: '10px' }}>$2,283.23</button>

  <a onClick={() => setIsOpen(false)} href="/jackpot" className={`${styles.link}`} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
    <img src={coinsStacked} alt="jackpot" style={{ width: 20, height: 20, marginRight: 5 }} />
    <span style={{ marginRight: -25 }}>jackpot</span>
  </a>
  <button className={styles.btnLink} style={{ fontSize: '10px' }}>$2,283.23</button>
</div>

                {isAdmin && (
                  <NavLink data-admin="true" onClick={() => setIsOpen(false)} to="/admin" className={({ isActive }) => `${styles.link} ${isActive ? styles.active : ''}`}>
                    <Lock />
                    <span>Admin panel</span>
                  </NavLink>
                )}
              </div>

              {!!self ? (
                <>
                  <div className={styles.right}>
                    <button title="Settings" onClick={openSettings}>
                      <SettingsOutline />
                    </button>

                    <button title="Sign out" onClick={user.signOut}>
                      <SignOut />
                    </button>

                    <div className={styles.level}>
                      <p className={styles.lvl}>Lvl {self?.level || 0}</p>
                      <p className={styles.percent}>{parseInt(self?.levelCompletion || 0)}%</p>

                      <div style={{ height: `${self?.levelCompletion || 0}%` }} />
                    </div>

                    <img src={self?.avatar} alt="" className={styles.avatar} onClick={openProfile} />
                  </div>
                  <UserModal />
                </>
              ) : (
                <div className={styles.right}>
                  <Button type="button" className={styles.loginBtn} onClick={window.insolve.user.signIn} bgColor="#1D1E22" textColor="#757575" >
                    <span>Login</span>
                  </Button>
                  <Button type="button" onClick={window.insolve.user.signIn} variant="success" className={styles.steam} width="200" shiny>
                    <span>Sign Up</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;