// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Settings_input__r8du3 {\r\n  margin: 10px 0;\r\n  background-color: var(--bg-color-2);\r\n  outline: 0;\r\n  border: 0;\r\n  border-radius: 5px;\r\n  height: 40px;\r\n  line-height: 40px;\r\n  padding: 0 15px;\r\n  color: var(--text-color-primary);\r\n}\r\n\r\n.Settings_bottom__kcpCX {\r\n  margin-top: auto;\r\n}\r\n\r\n.Settings_bottom__kcpCX button {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: 12px 0 0;\r\n}\r\n\r\n.Settings_error__\\+9ZQc {\r\n  font-size: 14px;\r\n  color: var(--roulette-red-single);\r\n}\r\n\r\n.Settings_success__PyP4u {\r\n  font-size: 14px;\r\n  margin-top: auto;\r\n  color: var(--roulette-green-single);\r\n  text-align: center;\r\n}", "",{"version":3,"sources":["webpack://./src/domain/UserModal/pages/Settings/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mCAAmC;EACnC,UAAU;EACV,SAAS;EACT,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mCAAmC;EACnC,kBAAkB;AACpB","sourcesContent":[".input {\r\n  margin: 10px 0;\r\n  background-color: var(--bg-color-2);\r\n  outline: 0;\r\n  border: 0;\r\n  border-radius: 5px;\r\n  height: 40px;\r\n  line-height: 40px;\r\n  padding: 0 15px;\r\n  color: var(--text-color-primary);\r\n}\r\n\r\n.bottom {\r\n  margin-top: auto;\r\n}\r\n\r\n.bottom button {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: 12px 0 0;\r\n}\r\n\r\n.error {\r\n  font-size: 14px;\r\n  color: var(--roulette-red-single);\r\n}\r\n\r\n.success {\r\n  font-size: 14px;\r\n  margin-top: auto;\r\n  color: var(--roulette-green-single);\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "Settings_input__r8du3",
	"bottom": "Settings_bottom__kcpCX",
	"error": "Settings_error__+9ZQc",
	"success": "Settings_success__PyP4u"
};
export default ___CSS_LOADER_EXPORT___;
