import React from 'react';
import styles from './index.module.css'; // Import CSS module for styling

const ChatBtn = ({ width, height, text, color, textColor, onClick }) => {
  const buttonStyle = {
    width: width,
    height: height,
    color:textColor,
    backgroundColor: color // Use the color prop for background color
  };

  return (
    <button
      className={styles.button} // Apply CSS class for styling
      style={buttonStyle}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default ChatBtn;
