import { Link } from 'react-router-dom';

import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { Tiktok } from '@styled-icons/boxicons-logos/Tiktok';
import { DiscordAlt as Discord } from '@styled-icons/boxicons-logos/DiscordAlt';
import allLogo from '../../resources/images/logowname.png';
import styles from './index.module.css';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.left}>
      <img src={allLogo} className={styles.logo} alt="" />
      <p style={{color: "#757575"}}>Lorem ipsum dolor sit amet consectetur.</p>

      <p style={{marginTop: '20px', color: "#757575"}}>Lorem ipsum dolor sit amet consectetur. Neque proin mi morbi eget fermentum augue ultrices metus dictum. Vulputate fringilla tellus phasellus.</p>
      <p>
        {/* <a href="mailto:contact@emeralds.gg">contact@emeralds.gg</a> */}
      </p>
    </div>



    {/* <div className={styles.marquee}>
      <marquee>
        <img src={logo} alt="" />
        <img src={logo} alt="" />
        <img src={logo} alt="" />
        <img src={logo} alt="" />
        <img src={logo} alt="" />
        <img src={logo} alt="" />
      </marquee>
    </div> */}



    <div className={styles.right}>
      <div>
        <p>Platform</p>
        <ul>
          <li><Link to="/support"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Support</p></Link></li>
          <li><Link to="/provably-fair"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Provably Fair</p></Link></li>
          <li><Link to="/faq"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>FAQ</p></Link></li>
        </ul>
      </div>

      <div>
        <p>About Us</p>
        <ul>
          <li><Link to="/privacy-policy"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>AML Policy</p></Link></li>
          <li><Link to="/tos"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Terms of Service</p></Link></li>
          <li><Link to="/privacy-policy"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Privacy Policy</p></Link></li>
          <li><Link to="/privacy-policy"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Responsible Gambling</p></Link></li>
        </ul>
      </div>

      <div>
        <p>Community</p>
        <ul>
          <li>  
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <span><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Twitter</p></span>
            </a>
          </li>

          <li>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <span><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Instagram</p></span>
            </a>
          </li>

          <li>
            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
              <span><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Tiktok</p></span>
            </a>
          </li>

          <li>
            <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
              <span><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Discord</p></span>
            </a>
          </li>
        </ul>
      </div>

      <div>
        <p>Partners</p>
        <ul>
          <li><Link to="/privacy-policy"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Partnership Program</p></Link></li>
          <li><Link to="/privacy-policy"><p style={{color: "#757575", fontSize: "12px", fontWeight: "500" }}>Affiliates</p></Link></li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer;