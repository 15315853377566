// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingSimple_loader__6KBd0 {\n  border-radius: 50%;\n  border: 2px solid var(--bg-color-1);\n  border-top: 2px solid var(--theme-color);\n  width: 14px;\n  height: 14px;\n  animation: LoadingSimple_spin__xnqlv 2s linear infinite;\n}\n\n@keyframes LoadingSimple_spin__xnqlv {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}", "",{"version":3,"sources":["webpack://./src/components/LoadingSimple/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,wCAAwC;EACxC,WAAW;EACX,YAAY;EAEZ,uDAAkC;AACpC;;AAOA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".loader {\n  border-radius: 50%;\n  border: 2px solid var(--bg-color-1);\n  border-top: 2px solid var(--theme-color);\n  width: 14px;\n  height: 14px;\n  -webkit-animation: spin 2s linear infinite;\n  animation: spin 2s linear infinite;\n}\n\n@-webkit-keyframes spin {\n  0% { -webkit-transform: rotate(0deg); }\n  100% { -webkit-transform: rotate(360deg); }\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "LoadingSimple_loader__6KBd0",
	"spin": "LoadingSimple_spin__xnqlv"
};
export default ___CSS_LOADER_EXPORT___;
