// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlertManager_alerts__avN6r {\n  position: fixed;\n  right: 50px;\n  bottom: 40px;\n  width: 320px;\n  z-index: 999;\n}\n\n@media (max-width: 768px) {\n  .AlertManager_alerts__avN6r {\n    left: 20px;\n    right: 20px;\n    width: auto;\n  }\n}", "",{"version":3,"sources":["webpack://./src/domain/AlertManager/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,UAAU;IACV,WAAW;IACX,WAAW;EACb;AACF","sourcesContent":[".alerts {\n  position: fixed;\n  right: 50px;\n  bottom: 40px;\n  width: 320px;\n  z-index: 999;\n}\n\n@media (max-width: 768px) {\n  .alerts {\n    left: 20px;\n    right: 20px;\n    width: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alerts": "AlertManager_alerts__avN6r"
};
export default ___CSS_LOADER_EXPORT___;
