import React, { createContext, useContext, useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';

const SocketContext = createContext();
export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        const host = "https://rust-framework.dev2.prodevr.com";
        const newSocket = socketIOClient(host, { transports: ["websocket"] });

        newSocket.on('connect', () => {
            setConnected(true);
            console.log('Connected to socket:', newSocket);
            // newSocket.emit('handshake', 'your_token_here');
        });

        // newSocket.on('disconnect', () => {
        //     setConnected(false);
        //     console.log('Disconnected from socket');
        // });
        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, []);

    const join = (data) => {
        if (socket) {
            socket.emit('tf2_coinflip:joinSession', { data });
        }
    };

    return (
        <SocketContext.Provider value={{ socket, connected }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => useContext(SocketContext);